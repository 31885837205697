/**
 * "Cleans" the passed object for any nullish values (e.g. "inserted: null")
 *
 * @param   obj  The object that you want to clean.
 * @returns The object that you passed in but cleaner (removed keys with nullish values).
 */
export function cleanObject(obj: any) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}
