var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hasMemberOrgAccess ? _c('GuestUserListPage') : _c('v-form', [_c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function callback($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseOverviewTabs.Open)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-check")]), _vm._v(" Åpne/Planlagte ")], 1), _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.CourseOverviewTabs.NotOpen)
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-text")]), _vm._v(" Andre ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleCourse"
          }
        }, [_vm._v("Oversikt")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk etter ID eller Tittel",
            "single-line": "",
            "hide-details": ""
          },
          on: {
            "input": _vm.updateSearch
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "newButton"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: _vm.CourseRouteNames.NewCoursePrerequisites
              });
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Opprett ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": _vm.CourseOverviewTabs.Open
    }
  }, [_c('CourseListTable', {
    attrs: {
      "courseList": _vm.courses,
      "paginationValues": _vm.paginationValues,
      "query": _vm.query,
      "pageState": _vm.pageState
    },
    on: {
      "updateQuery": function updateQuery($event) {
        return _vm.updateQuery($event);
      },
      "updatePageState": _vm.updatePageState
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.CourseOverviewTabs.NotOpen
    }
  }, [_c('CourseListTable', {
    attrs: {
      "courseList": _vm.courses,
      "paginationValues": _vm.paginationValues,
      "query": _vm.query,
      "pageState": _vm.pageState
    },
    on: {
      "updateQuery": function updateQuery($event) {
        return _vm.updateQuery($event);
      },
      "updatePageState": _vm.updatePageState
    }
  })], 1)], 1)]], 2), _c('portal', {
    attrs: {
      "to": "tableFilter"
    }
  }, [_c('CourseListFilter', {
    attrs: {
      "query": _vm.query,
      "showOpenCourses": _vm.currentTab === _vm.CourseOverviewTabs.Open
    },
    on: {
      "updateQuery": function updateQuery($event) {
        return _vm.updateQuery($event, true);
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }