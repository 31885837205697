
import { api } from "@/api/api";
import { ApiGetCourseListDto, ApiSortOrder } from "@/api/generated/Api";
import CourseListFilter from "@/components/course/overview/CourseListFilter.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import GuestUserListPage from "@/pages/kurs/list/guestUser/GuestUserListPage.vue";
import { openCourseStatuses, otherCourseStatuses } from "@/shared/constants/courseStatuses";
import { CourseResponsibleFilter } from "@/shared/enums/courseResponsibleFilter.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { PaginationValues } from "@/shared/interfaces/TablePaginationInterface";
import { useRouter } from "@/shared/useHelpers";
import { cleanObject } from "@/shared/helpers/objectHelpers";
import { isNumeric } from "@/shared/helpers/stringHelpers";
import { defineComponent, ref, watch } from "@vue/composition-api";
import debounce from "lodash.debounce";
import { DataOptions } from "vuetify";
import CourseListTable from "./CourseListTable.vue";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";

export interface PaginationCourseListQueryInterface {
  CourseId?: number;
  CourseName?: string;
  OrganizerOrganizationId?: number;
  Status?: string;
  TeachingMethod?: string;
  CourseSupervisorId?: number;
  LecturerId?: number;
  SortBy?: string;
  SortOrder?: ApiSortOrder;
  PageNumber?: number;
  PageSize?: number;
}

const CourseOverviewStorageKey = "courseListQuery";
const CourseOverviewSearchKey = "courseListSearch";

enum CourseOverviewTabs {
  Open = "open",
  NotOpen = "notopen",
}

export default defineComponent({
  name: "CourseListPage",
  components: {
    BaseLayout,
    CourseListTable,
    CourseListFilter,
    GuestUserListPage,
  },
  setup() {
    const router = useRouter();
    const search = ref(window.sessionStorage.getItem(CourseOverviewSearchKey) || "");
    const pageState = ref<DataOptions>({
      page: 1,
      itemsPerPage: 30,
      sortBy: ["id"],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: [true],
    });

    // Get the stored query values and set as initial values
    const storedQueryValues = window.localStorage.getItem(CourseOverviewStorageKey)
      ? JSON.parse(window.localStorage.getItem(CourseOverviewStorageKey) || "")
      : {};
    const query = ref<PaginationCourseListQueryInterface>({
      ...storedQueryValues,
      PageNumber: pageState.value.page,
      PageSize: pageState.value.itemsPerPage,
      SortBy: "id",
      SortOrder: 1,
      CourseId: search.value.length > 0 && isNumeric(search.value) ? search.value : undefined,
      CourseName: search.value && !isNumeric(search.value) ? search.value : undefined,
    });
    const paginationValues = ref<PaginationValues>({
      isLoading: false,
      pageCount: 0,
      serverItemsLength: 0,
    });
    const courses = ref<ApiGetCourseListDto[]>([]);
    const currentTab = ref(CourseOverviewTabs.Open);
    const isFilterOpen = ref(false);

    watch(currentTab, async () => {
      router.replace({ query: { tab: currentTab.value } });
      await getCourses();
    });

    watch(
      query,
      () => {
        debounceFetch();
      },
      { deep: true }
    );

    const getCourses = async () => {
      paginationValues.value.isLoading = true;

      const statuses = currentTab.value === CourseOverviewTabs.Open ? openCourseStatuses : otherCourseStatuses;

      const response = (
        await api.course.getCourseList({
          CourseResponsibleFilter: CourseResponsibleFilter.All,
          ...cleanObject(query.value),
          Statuses: query.value.Status ? [query.value.Status] : statuses,
        })
      ).data;

      paginationValues.value = {
        isLoading: false,
        pageCount: response.totalPages,
        serverItemsLength: response.totalCount,
      };

      courses.value = response.items || [];
    };

    const debounceFetch = debounce(getCourses, 500);

    const updateQuery = (updatedQuery: PaginationCourseListQueryInterface, resetPageState = false) => {
      if (resetPageState) {
        query.value = {
          ...updatedQuery,
          PageNumber: 1,
        };
        pageState.value.page = 1;
      } else {
        query.value = updatedQuery;
      }

      // Store the query in local storage
      window.localStorage.setItem(CourseOverviewStorageKey, JSON.stringify(updatedQuery));
    };

    const updatePageState = (newPageState: DataOptions) => {
      pageState.value = newPageState;
    };

    const updateSearch = (value: string) => {
      search.value = value;

      if (isNumeric(value) && value.length > 0) {
        query.value = {
          ...query.value,
          CourseId: Number(value),
          CourseName: undefined,
          PageNumber: 1,
        };
      } else {
        query.value = {
          ...query.value,
          CourseName: value || undefined,
          CourseId: undefined,
          PageNumber: 1,
        };
      }

      pageState.value.page = 1;

      // Store the search text in session storage
      window.sessionStorage.setItem(CourseOverviewSearchKey, value);
    };

    return {
      query,
      search,
      paginationValues,
      pageState,
      courses,
      hasMemberOrgAccess,
      formatDateShort,
      currentTab,
      isFilterOpen,
      updateQuery,
      updatePageState,
      CourseOverviewTabs,
      CourseRouteNames,
      updateSearch,
    };
  },
});
