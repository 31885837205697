var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function callback($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#open"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-check")]), _vm._v(" Åpne/Planlagte ")], 1), _c('v-tab', {
          attrs: {
            "href": "#notopen"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-text")]), _vm._v(" Andre ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleCourse"
          }
        }, [_vm._v("Oversikt")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-autocomplete', {
          staticClass: "mt-5 pt-1 mr-5",
          attrs: {
            "items": _vm.groupByItems,
            "item-text": "text",
            "item-value": "value",
            "label": "Grupperinger",
            "single-line": "",
            "clear-icon": "mdi-arrow-left",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: _vm.currentGroupBy,
            callback: function callback($$v) {
              _vm.currentGroupBy = $$v;
            },
            expression: "currentGroupBy"
          }
        }), _c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "data-cy": "searchCourseList",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Opprett",
            "icon": "mdi-plus",
            "dataCy": "newButton"
          },
          on: {
            "handleClick": function handleClick($event) {
              return _vm.$router.push("/kurs/ny");
            }
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "open"
    }
  }, [_c('GuestUserListTable', {
    attrs: {
      "courseList": _vm.openCourses,
      "search": _vm.search,
      "currentGroupBy": _vm.currentGroupBy
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "notopen"
    }
  }, [_c('GuestUserListTable', {
    attrs: {
      "courseList": _vm.notOpenCourses,
      "search": _vm.search,
      "currentGroupBy": _vm.currentGroupBy
    }
  })], 1)], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }