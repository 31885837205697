var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTableFiltered', _vm._b({
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courseList,
      "options": _vm.pageState,
      "pageCount": _vm.paginationValues.pageCount,
      "server-items-length": _vm.paginationValues.serverItemsLength,
      "loading": _vm.paginationValues.isLoading,
      "multiSort": false,
      "hasServerSidePagination": true
    },
    on: {
      "update:options": _vm.updatePagination
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "openCourse-".concat(item.id)
                },
                on: {
                  "click": function click($event) {
                    return _vm.navigateToCoursePage(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Åpne kurs")])]), !_vm.hasMemberOrgAccess ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "editCourse-".concat(item.id)
                },
                on: {
                  "click": function click($event) {
                    return _vm.navigateToEditCourse(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger kurs")])]) : _vm._e()];
      }
    }, {
      key: "item.startDate",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatDateShort(value) : "") + " ")];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatDateShort(value) : "") + " ")];
      }
    }, {
      key: "item.enrollmentDeadline",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatDateShort(value) : "") + " ")];
      }
    }], null, true)
  }, 'BaseTableFiltered', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }