
import { ApiGetCourseListDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { PaginationValues } from "@/shared/interfaces/TablePaginationInterface";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import { DataOptions } from "vuetify";
import { PaginationCourseListQueryInterface } from "./CourseListPage.vue";
import { getHeaders, getNavigateToCoursePage, getNavigateToEditCourse } from "./tableUtils";

export default defineComponent({
  name: "CourseListTable",
  components: { BaseTableFiltered },
  props: {
    header: {
      type: Array,
      required: false,
    },
    courseList: {
      type: Array as PropType<ApiGetCourseListDto[]>,
      required: true,
    },
    paginationValues: {
      type: Object as PropType<PaginationValues>,
      required: true,
    },
    query: {
      type: Object as PropType<PaginationCourseListQueryInterface>,
      required: true,
    },
    pageState: {
      type: Object as PropType<DataOptions>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const headers = getHeaders(hasMemberOrgAccess);

    const updatePagination = (paginationData: any) => {
      const updatedQuery = {
        ...props.query,
        PageNumber: paginationData.page,
        PageSize: paginationData.itemsPerPage > 0 ? paginationData.itemsPerPage : undefined,
        SortBy: paginationData.sortBy[0], // API doesn't support multi sort. Multi sort is disabled on table
        SortOrder: paginationData.sortDesc.length === 0 ? undefined : paginationData.sortDesc[0] ? 1 : 0,
      };
      emit("updateQuery", updatedQuery);
      emit("updatePageState", paginationData);
    };

    return {
      formatDateShort,
      navigateToCoursePage: getNavigateToCoursePage(router),
      navigateToEditCourse: getNavigateToEditCourse(router),
      updatePagination,
      headers,
      hasMemberOrgAccess,
    };
  },
});
