
import { computed, defineComponent, onMounted, PropType, ref, Ref } from "@vue/composition-api";
import EmployeeSearchInput from "@/components/common/EmployeeSearchInput.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { PaginationCourseListQueryInterface } from "@/pages/kurs/list/CourseListPage.vue";
import { TeachingMethods } from "@/shared/enums/teaching-methods.enum";
import { ApiIdNameDtoType } from "@/api/generated/Api";
import { api } from "@/api/api";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { openCourseStatuses, otherCourseStatuses } from "@/shared/constants/courseStatuses";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";

export default defineComponent({
  name: "CourseListFilter",
  components: {
    EmployeeSearchInput,
  },
  props: {
    query: { type: Object as PropType<PaginationCourseListQueryInterface>, required: true },
    showOpenCourses: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const organizerOrganizations = ref<ApiIdNameDtoType[]>();
    const loadingOrganizerOrganizations = ref<boolean>(false);
    const formValues: Ref<PaginationCourseListQueryInterface> =
      useAutoDeepCloneProp<PaginationCourseListQueryInterface>(props, "query");

    const courseStatusItems = computed(() => (props.showOpenCourses ? openCourseStatuses : otherCourseStatuses));

    const fetchOrganizerOrganizations = async () => {
      loadingOrganizerOrganizations.value = true;
      organizerOrganizations.value = (
        await api.organization.getPotentialOrganizerOrganizations({ isOrganizer: true })
      ).data?.sort(localeSortByProperty("name"));
      loadingOrganizerOrganizations.value = false;
    };

    onMounted(async () => {
      if (!hasMemberOrgAccess) {
        await fetchOrganizerOrganizations();
      }
    });

    const removeFilter = () => {
      formValues.value = {
        ...formValues.value,
        CourseSupervisorId: undefined,
        LecturerId: undefined,
        OrganizerOrganizationId: undefined,
        Status: undefined,
        TeachingMethod: undefined,
      };

      emit("updateQuery", formValues.value);
    };

    return {
      formValues,
      organizerOrganizations,
      courseStatusItems,
      teachingMethodsItems: Object.values(TeachingMethods) as string[],
      loadingOrganizerOrganizations,
      hasMemberOrgAccess,
      removeFilter,
    };
  },
});
