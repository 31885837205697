
import { ApiGetCourseListDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import { getHeaders, getNavigateToCoursePage, getNavigateToEditCourse } from "@/pages/kurs/list/tableUtils";

export default defineComponent({
  name: "GuestUserListTable",
  components: { BaseTableFiltered },
  props: {
    header: {
      type: Array,
      required: false,
    },
    courseList: {
      type: Array as PropType<ApiGetCourseListDto[]>,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    currentGroupBy: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const headers = getHeaders(hasMemberOrgAccess);

    const formatGroupBy = (group: string) => {
      if (props.currentGroupBy) {
        // Get the header item by currentGroupBy
        const headerItem = headers.find((x) => x.value === props.currentGroupBy);

        if (["startDate", "endDate", "enrollmentDeadline"].includes(props.currentGroupBy)) {
          return `${headerItem && `${headerItem.text}:`} ${formatDateShort(group)}`;
        }

        return `${headerItem && `${headerItem.text}:`} ${group}`;
      }
    };

    return {
      formatDateShort,
      navigateToCoursePage: getNavigateToCoursePage(router),
      navigateToEditCourse: getNavigateToEditCourse(router),
      formatGroupBy,
      headers,
      hasMemberOrgAccess,
    };
  },
});
