
import { api } from "@/api/api";
import { ApiGetCourseListDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import GuestUserListTable from "./GuestUserListTable.vue";

export default defineComponent({
  name: "GuestUserListPage",
  components: {
    BaseTooltipIconButton,
    BaseLayout,
    GuestUserListTable,
  },
  setup() {
    const router = useRouter();

    const openCourses = ref<ApiGetCourseListDto[]>([]);
    const notOpenCourses = ref<ApiGetCourseListDto[]>([]);

    const search = ref<string>(window.sessionStorage.getItem("courseSearch") || "");
    const currentTab = ref("open");
    const isFilterOpen = ref(false);
    const currentGroupBy = ref("");

    const groupByItems = [
      { text: "Sted", value: "areaName" },
      {
        text: "Arrangør",
        value: "organizerOrganizationName",
      },
      { text: "Oppstart", value: "startDate" },
      { text: "Slutt", value: "endDate" },
      {
        text: "Status",
        value: "status",
      },
      { text: "Påmeldingsfrist", value: "enrollmentDeadline" },
      { text: "Undervisningsform", value: "teachingMethod" },
      { text: "Ansvarlig", value: "courseSupervisorName" },
      { text: "Administrator", value: "lecturerName" },
    ];

    watch(search, () => {
      window.sessionStorage.setItem("courseSearch", search.value);
    });

    watch(currentTab, async () => {
      router.replace({ query: { tab: currentTab.value } });
      await getCourses();
    });

    const getCourses = async () => {
      const response = (await api.guestside.getGuestUserCoursList()).data;

      openCourses.value = response.filter((x) => x.status === CourseStatus.Open || x.status === CourseStatus.Planned);

      notOpenCourses.value = response.filter(
        (x) => x.status !== CourseStatus.Open && x.status !== CourseStatus.Planned
      );
    };

    onMounted(async () => {
      getCourses();
    });

    return {
      openCourses,
      notOpenCourses,
      search,
      hasMemberOrgAccess,
      formatDateShort,
      currentTab,
      isFilterOpen,
      currentGroupBy,
      groupByItems,
    };
  },
});
