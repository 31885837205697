var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('v-list', [!_vm.hasMemberOrgAccess ? _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "pa-2"
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Arrangør",
      "items": _vm.organizerOrganizations,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "hide-details": "",
      "dense": "",
      "data-cy": "filterOrganizerOrganization",
      "loading": _vm.loadingOrganizerOrganizations
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('updateQuery', _vm.formValues);
      }
    },
    model: {
      value: _vm.formValues.OrganizerOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "OrganizerOrganizationId", $$v);
      },
      expression: "formValues.OrganizerOrganizationId"
    }
  })], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "pa-2"
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.courseStatusItems,
      "label": "Status",
      "hide-details": "",
      "data-cy": "filterStatus",
      "clearable": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('updateQuery', _vm.formValues);
      }
    },
    model: {
      value: _vm.formValues.Status,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "Status", $$v);
      },
      expression: "formValues.Status"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "pa-2"
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.teachingMethodsItems,
      "label": "Undervisningsform",
      "hide-details": "",
      "data-cy": "filterTeachingMethod",
      "clearable": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('updateQuery', _vm.formValues);
      }
    },
    model: {
      value: _vm.formValues.TeachingMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "TeachingMethod", $$v);
      },
      expression: "formValues.TeachingMethod"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "pa-2"
  }, [_c('EmployeeSearchInput', {
    attrs: {
      "label": "Ansvarlig",
      "mandatory": false,
      "cypressId": "filterCourseSupervisor",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('updateQuery', _vm.formValues);
      }
    },
    model: {
      value: _vm.formValues.CourseSupervisorId,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "CourseSupervisorId", $$v);
      },
      expression: "formValues.CourseSupervisorId"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "pa-2"
  }, [_c('EmployeeSearchInput', {
    attrs: {
      "label": "Administrator",
      "mandatory": false,
      "cypressId": "filterCourseLecturer",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('updateQuery', _vm.formValues);
      }
    },
    model: {
      value: _vm.formValues.LecturerId,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "LecturerId", $$v);
      },
      expression: "formValues.LecturerId"
    }
  })], 1)], 1)], 1), _c('v-list-item', {
    staticClass: "pa-2"
  }, [_c('v-list-item-content', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.removeFilter
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")]), _vm._v(" Tøm filter ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }