import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import VueRouter from "vue-router";

export const getHeaders = (hasMemberOrgAccess: boolean) => [
  { text: "ID", value: "id" },
  { text: "Handlinger", value: "actions", sortable: false },
  ...(!hasMemberOrgAccess ? [{ text: "Produktnr", value: "erpProductNo" }] : []),
  { text: "Tittel", value: hasMemberOrgAccess ? "externalTitle" : "courseName" },
  { text: "Sted", value: "areaName" },
  {
    text: "Arrangør",
    value: "organizerOrganizationName",
    filter: hasMemberOrgAccess,
  },
  { text: "Oppstart", value: "startDate" },
  { text: "Slutt", value: "endDate" },
  {
    text: "Status",
    value: "status",
    filter: hasMemberOrgAccess,
  },
  {
    text: "Påmeldt",
    value: "enrolledStudentsCount",
  },
  { text: "Påmeldingsfrist", value: "enrollmentDeadline" },
  { text: "Undervisningsform", value: "teachingMethod", filter: hasMemberOrgAccess },
  { text: "Ansvarlig", value: "courseSupervisorName", filter: hasMemberOrgAccess },
  { text: "Administrator", value: "lecturerName", filter: hasMemberOrgAccess },
];

export const getNavigateToEditCourse = (router: VueRouter) => (courseId: number) => {
  router.push({
    name: CourseRouteNames.EditCourse,
    params: {
      editCourseId: courseId.toString(),
    },
  });
};

export const getNavigateToCoursePage = (router: VueRouter) => (courseId: number) => {
  router.push({
    name: SingleCourseRouteNames.CourseDashboard,
    params: {
      id: courseId.toString(),
    },
  });
};
